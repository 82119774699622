import { isNil, find } from 'ramda';

export const GRADES_CATEGORIES = ['F', 'D-', 'D', 'D+', 'C-', 'C', 'C+', 'B-', 'B', 'B+', 'A-', 'A', 'A+'];

const grades = [
  { value: 'A+', bounds: [49, 50] },
  { value: 'A', bounds: [45, 49] },
  { value: 'A-', bounds: [41, 45] },
  { value: 'B+', bounds: [37, 41] },
  { value: 'B', bounds: [33, 37] },
  { value: 'B-', bounds: [29, 33] },
  { value: 'C+', bounds: [25, 29] },
  { value: 'C', bounds: [21, 25] },
  { value: 'C-', bounds: [17, 21] },
  { value: 'D+', bounds: [13, 17] },
  { value: 'D', bounds: [9, 13] },
  { value: 'D-', bounds: [5, 9] },
  { value: 'F', bounds: [0, 5] },
];

const isBetween = (value, min, max) => value > min && value <= max;

export function ratingToGrade(rating) {
  const floatRating = parseFloat(rating);

  const currentGrade = find(grade => {
    const [min, max] = grade.bounds;
    return isBetween(floatRating, min, max);
  })(grades);

  return isNil(currentGrade) ? '-' : currentGrade.value;
}

const CLASSES = {
  'A+': 'rate-13',
  A: 'rate-12',
  'A-': 'rate-11',
  'B+': 'rate-10',
  B: 'rate-9',
  'B-': 'rate-8',
  'C+': 'rate-7',
  C: 'rate-6',
  'C-': 'rate-5',
  'D+': 'rate-4',
  D: 'rate-3',
  'D-': 'rate-2',
  F: 'rate-1',
  '?': 'rate-0',
};

export function toCssClass(grade) {
  return CLASSES[grade];
}

export function getNpsPosition(value) {
  if (value < -100) {
    return 0;
  }
  if (value > 100) {
    return 100;
  }
  return Math.round((value + 100) / 2);
}
