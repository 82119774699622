import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { isBlank } from 'utils/Utils';
import styles from './styles';

class BaseAvatar extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    className: PropTypes.string,
    initials: PropTypes.string,
    imageUrl: PropTypes.string,
    size: PropTypes.string,
    border: PropTypes.bool,
    square: PropTypes.bool,
  };

  static defaultProps = {
    square: false,
    border: true,
    size: null,
    imageUrl: null,
    initials: '',
  };

  render() {
    const { classes, className, initials, imageUrl, border, square, size } = this.props;

    const cl = cn(classes.root, size && classes[`size_${size}`], {
      [classes.isSquare]: square,
      [classes.hasBorder]: !imageUrl || border,
      [className]: !isBlank(className),
    });

    return (
      <div className={cl}>
        {imageUrl ? (
          <div className={classes.imageContainer}>
            <img src={imageUrl} className={classes.image} alt="BaseAvatar" data-node="avatar-img" />
          </div>
        ) : (
          <div className={classes.initials} data-node="avatar-initials">
            {initials}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(BaseAvatar);
