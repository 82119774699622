import { makeStyles } from '@material-ui/core';

export default makeStyles({
  root: {
    minWidth: 35,
    minHeight: 35,
  },
  inner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  icon: {}, // may be overridden with a `classes` prop somewhere? Unclear
});
