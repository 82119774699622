export default theme => {
  const styles = {
    root: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },

    thin: {
      // this let the maxWidth increase when the font size increases
      maxWidth: theme.typography.pxToRem(200),
    },

    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },

    placeholder: {
      color: theme.palette.text.disabled,
    },

    chip: {
      '-webkit-text-fill-color': theme.palette.text.secondary,
    },

    listsItem: {
      whiteSpace: 'normal',
    },

    optionsDescription: {
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(11),
    },

    popover: {
      maxWidth: '75%',
    },

    colorRadio: {
      display: 'none',

      '&:disabled + $paint': {
        opacity: 0.5,
      },
    },

    paint: {
      display: 'block',
      width: 7,
      height: 7,
      borderRadius: '50%',
      marginRight: 7,
    },

    colorLabel: {
      cursor: 'pointer',
    },

    statusList: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      maxWidth: 'fit-content',
    },

    textContent: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    productStatusItem: {
      display: 'flex',
      alignItems: 'center',
    },
  };

  Object.keys(theme.palette.status).forEach(status => {
    styles[status] = {
      backgroundColor: theme.palette.status[status],
    };
  });

  return styles;
};
