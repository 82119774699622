import { Theme } from '@material-ui/core/styles';

const FormLabel: Theme['overrides'] = {
  MuiFormLabel: {
    root: {
      color: '#383C45',
      marginBottom: 15,
      fontWeight: 'bold',
      fontSize: '1rem',
      lineHeight: '1rem',

      '&$focused': {
        color: '#383C45',
      },
    },
  },
};

export default FormLabel;
