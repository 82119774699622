export default {
  learnPlatformResourcesPath: () => 'https://learnplatform.com/resources',
  coursewareInContextPath: () => 'http://www.coursewareincontext.org',
  learnPlatformGsuitePath: () => 'http://learnplatform.com/gsuite/',
  learnPlatformPrivacyPolicy: () => 'https://learnplatform.com/privacy-policy',
  learnPlatformTermsOfService: () => 'https://learnplatform.com/terms-of-service',
  sdpcPath: () => 'https://sdpc.a4l.org',
  intercomPath: () => gon.intercomLink,
  helpCenterPath: () => 'https://learnplatform.helpdocs.io',
  requestADemoPath: () => 'https://www.instructure.com/k12/products/learnplatform/request-a-demo',
  instDeveloperDocs: () => 'https://developerdocs.instructure.com',
};
