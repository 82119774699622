import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';

const BUTTON_OPTIONS = {
  anchor: 'a',
  link: 'link',
  button: 'button',
  submit: 'submit',
};

const ButtonBase = ({
  component,
  className,
  activeClassName,
  to,
  href,
  type,
  role,
  disabled,
  onClick,
  isActive,
  variant,
  ...rest
}) => {
  let Element = component;
  const props = {
    className,
    draggable: false,
    onClick,
    ...rest,
  };
  if (href) {
    Element = BUTTON_OPTIONS.anchor;
    props.href = href;
  } else if (to) {
    Element = Link;
    props.to = to;
    props.role = role || BUTTON_OPTIONS.link;
    if (activeClassName) {
      Element = NavLink;
      props.activeClassName = activeClassName;
      if (isActive) {
        props.isActive = isActive;
      }
    }
  }

  if (variant === BUTTON_OPTIONS.link) {
    Element = BUTTON_OPTIONS.anchor;
  }

  if (Element === BUTTON_OPTIONS.button) {
    props.type = type;
    props.disabled = disabled;
  } else if (Element !== BUTTON_OPTIONS.anchor && Element !== Link && Element !== NavLink) {
    props.role = role || BUTTON_OPTIONS.button;
  }

  if (variant === BUTTON_OPTIONS.submit) {
    props.type = 'submit';
  }

  return <Element {...props} />;
};

ButtonBase.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.string]),
  type: PropTypes.string,
  role: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isActive: PropTypes.func,
  variant: PropTypes.oneOf([
    'default',
    'link',
    'filter',
    'filterActive',
    'select',
    'social',
    'header',
    'visibilityToggle',
    'visibilityToggleHidden',
    'text',
    'submit',
  ]),
};

ButtonBase.defaultProps = {
  component: 'button',
  type: 'button',
  children: null,
  className: '',
  activeClassName: '',
  to: '',
  href: '',
  role: '',
  disabled: false,
  variant: 'default',
};

export default ButtonBase;
