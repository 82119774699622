import { Theme } from '@material-ui/core/styles';

const TableRow: Theme['overrides'] = {
  MuiTableRow: {
    root: {
      '&$selected': {
        backgroundColor: '#f5f5f5 !important',
      },
    },

    hover: {
      '&:hover': {
        backgroundColor: '#f5f5f5 !important',
      },
    },
  },
};

export default TableRow;
