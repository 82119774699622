import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    color: theme.palette.gray50,
  },

  checked: {
    color: theme.palette.checkbox,
  },
}));
