import { Theme } from '@material-ui/core/styles';

const Typography: Theme['overrides'] = {
  MuiTypography: {
    h6: {
      marginBottom: 14,
      fontWeight: 400,
    },
  },
};

export default Typography;
