const base = {
  white: '#FFFFFF',
  blue0: '#F6FBFF',
  blue1: '#0374B5',
  blue5: '#DBEFFE',
  blue10: '#C0E4FE',
  blue20: '#88C9F8',
  blue30: '#52ADEE',
  blue40: '#2391E0',
  blue50: '#0077CC',
  blue60: '#0066AE',
  blue65: '#0374B5',
  blue70: '#00508A',
  blue80: '#00385F',
  blue90: '#001E33',

  green0: '#F4FFF4',
  green5: '#DDFDDD',
  green10: '#D0FBD0',
  green20: '#B9F3B9',
  green30: '#9CE89C',
  green40: '#79D879',
  green50: '#51C251',
  green60: '#35A635',
  green70: '#198219',
  green80: '#055905',
  green90: '#002E00',

  yellow0: '#FFF9EF',
  yellow5: '#FFFBE3',
  yellow10: '#FFFCD5',
  yellow20: '#FFF4B5',
  yellow30: '#FFE585',
  yellow40: '#FFCF48',
  yellow50: '#FFB60A',
  yellow60: '#D29100',
  yellow70: '#9F6B00',
  yellow80: '#654403',
  yellow90: '#382606',

  orange0: '#FFF7EF',
  orange5: '#FFF0DA',
  orange10: '#FFE6C3',
  orange20: '#FFD09C',
  orange30: '#FFB06D',
  orange40: '#FC8837',
  orange50: '#E55B00',
  orange60: '#B24900',
  orange70: '#853900',
  orange80: '#612B00',
  orange90: '#462000',

  red0: '#FEEEEE',
  red5: '#FFE0E0',
  red10: '#FFD2D0',
  red20: '#FFB6B1',
  red30: '#FF9189',
  red40: '#FE675B',
  red50: '#E63B2A',
  red60: '#AF271B',
  red70: '#7F130D',
  red80: '#580502',
  red90: '#3B0100',

  magenta0: '#FEEEFF',
  magenta5: '#FFE1FF',
  magenta10: '#FFD2FF',
  magenta20: '#FFB2FF',
  magenta30: '#FF88F4',
  magenta40: '#F057D1',
  magenta50: '#D425A5',
  magenta60: '#9C157B',
  magenta70: '#6E0757',
  magenta80: '#4C003C',
  magenta90: '#33002A',

  gray0: '#F7F7F7',
  gray1: '#F5F5F5',
  gray2: '#C7CDD1',
  gray5: '#E7E8EA',
  gray10: '#D7D9DD',
  gray20: '#BABDC3',
  gray30: '#9DA1A9',
  gray40: '#80858E',
  gray45: '#6B7780',
  gray50: '#646974',
  gray60: '#494E5A',
  gray70: '#383C45',
  gray80: '#1D2027',
  gray90: '#090B0D',

  black: '#000000',

  // Instructure Colors

  primary: {
    licorice: '#2D3B45', // Primary Text
    electric: '#0770A3', // Brand Item, Link, Primary CTA, Neutral info alert, Progress
    white: '#FFFFFF', // Background
  },
  neutral: {
    oxford: '#394B58', // Side Navigation Background
    ash: '#556572', // Secondary Text
    tiara: '#556572', // Secondary Button Stroke, Menu Stroke, Divider
  },
  statuses: {
    crimson: '#D01A19', // Error messages / alerts
    fire: '#C23C0D', // warning messages and alerts
    shamrock: '#127A1B', // success messages and alerts
  },
  overlay: {
    a: '#384A58', // overlays, modals
    b: '#FFFFFF', // overlays, modals
  },
};

export default {
  ...base,
  page: '#FAFAFA',
  danger: base.red50,
  effectSize: {
    red: '#CB0200',
    yellow: '#F5A623',
    green: '#66B60F',
  },
  required: base.red50,
  error: {
    main: base.red50,
    background: '#FAEDEE',
  },
  status: {
    red: '#EE7C5C',
    orange: base.orange30,
    yellow: '#FFD856',
    lightYellow: '#FFEA98',
    lightGreen: base.green40,
    middleGreen: '#5DB282',
    green: '#5DB282',
    lightBlue: '#6EDEF0',
    blue: '#52B5D5',
    purple: '#978DD4',
    lightPurple: '#B8ADF0',
    pink: '#DA97C8',
    lightPink: '#E5B7D9',
    gray: '#C7CDD7',
    darkGray: '#A5ADBA',
    orangeRed: '#E63B2A',
    // old colors used by non-custom statuses
    darkGreen: base.green80,
    neutral: base.gray50,
    default: base.gray50,
  },
  statuses: {
    danger: base.red50,
    rejected: base.red50,
    info: base.blue50,
    default: base.gray50,
    warning: base.yellow40,
    accepted: base.yellow40,
    success: base.green40,
    submitted: base.green40,
  },
  terms: base.gray0,
  textField: {
    disabled: {
      background: base.gray0,
      text: '#7F7F7F',
    },
    filled: {
      background: base.gray0,
      text: base.gray70,
    },
  },
  text: {
    light: base.gray0,
    primary: base.primary.licorice,
    white: base.white,
    link: base.blue50,
    linkHover: base.blue50,
    disabled: base.gray20,
    secondary: base.gray50,
    hint: '#ABABAB',
    fade: base.gray10,
    dark: base.gray90,
  },
  list: {
    chosen: base.gray0,
    chosenDark: base.gray10,
    highlighted: '#E3F2FD',
    selected: '#FFFDE7',
  },
  background: {
    darkGray: base.gray0,
    white: base.white,
    lightGray: '#ECEDEE',
  },
  table: {
    evenRow: {
      backgroundColor: '#F9F9F9',
    },
    header: {
      light: base.gray5,
    },
  },
  filter: {
    background: {
      mobile: base.gray5,
      tagFilter: base.blue50,
      desktop: 'transparent',
      counter: base.blue50,
    },
    counterText: base.white,
    border: {
      tagFilter: base.blue50,
    },
    icon: base.blue50,
    iconActive: base.blue5,
    leads: '#3C8D5F',
  },
  panel: base.gray70,
  delimiter: '#F7F7F7',
  progressBar: {
    bar: base.blue50,
    barBackground: base.gray10,
  },
  buttons: {
    default: {
      color: base.white,
      backgroundColor: base.blue50,
    },
    disabled: {
      color: base.white,
      backgroundColor: base.gray30,
    },
    selectable: {
      backgroundColor: base.white,
      color: base.gray50,
    },
    visibilityToggle: {
      active: {
        color: base.blue50,
        backgroundColor: 'rgba(0, 119, 204, 0.08)',
      },
      hidden: {
        color: base.gray20,
        backgroundColor: '#F4F4F4',
      },
    },
    selected: {
      color: base.gray50,
      backgroundColor: '#EFEFEF',
    },
    decline: {
      backgroundColor: base.gray40,
    },
  },
  iconButton: {
    primary: {
      color: base.blue50,
      hover: {
        backgroundColor: base.blue5,
      },
    },
    info: {
      color: base.gray40,
    },
  },
  primary: {
    contrastText: base.white,
    main: base.blue50,
  },
  secondary: {
    main: base.gray70,
    contrastText: base.white,
  },
  borderWhite: base.white,
  usage: {
    page: base.white,
    border: '#F1F0ED',
    status: {
      positive: '#0077FF',
      negative: '#D0021B',
    },
  },
  checkbox: base.blue50,
  radio: base.blue50,
  mapper: {
    active: base.blue50,
  },
  admin: {
    link: base.blue50,
    button: {
      secondaryColor: base.blue50,
      hover: base.blue40,
    },
    status: {
      published: base.blue40,
      notPublished: '#F35455',
    },
    pill: {
      active: {
        backgroundColor: base.gray1,
        borderColor: '#C7CDD1',
        color: '#2D3B45',
      },
      disabled: {
        backgroundColor: base.white,
        borderColor: '#C7CDD1',
        color: '#2D3B45',
        opacity: 0.9,
      },
    },
  },
  surveyQuestion: {
    selected: {
      border: base.blue50,
      background: base.blue5,
    },
  },
  rubric: {
    selected: {
      border: base.blue50,
      background: '#EEFBFB',
    },
  },
  borderDark: base.gray20,
  mapping: {
    notMapped: base.gray50,
    mapped: '#95C480',
    incomplete: 'rgba(149, 196, 128, 0.65)',
    incompleteCard: 'rgba(149, 196, 128, 0.5)',
    ignored: '#6D9AC6',
    ignoredCard: 'rgba(38, 138, 188, 0.4)',
    warning: '#ECB253',
  },
  navbarItem: {
    selected: base.blue50,
  },
  success: {
    main: base.green40,
    contrastText: base.white,
  },
  border: '#E0E0E0',
  editorBorder: '#F1F1F1',
  borderLight: '#E6E6E6',
  borderLightGray: '#F0F0F0',
  borderGray: '#DDDDDD',
  borderLightBlue: base.blue20,
  borderBlue: base.blue1,
  waiting: '#909090',
  hover: 'rgba(0, 0, 0, 0.08)',
  stepper: base.blue40,
  message: {
    warning: {
      text: '#856404',
      background: '#FFF3CD',
      border: '#FFEEBA',
    },
  },
  social: {
    twitter: '#1DA1F2',
    google: '#E8433A',
    facebook: '#3B5998',
  },
  extension: {
    default: '#2A2C2F',
  },
  tooltip: {
    background: '#4A4A4A',
    shadow: 'rgba(56, 60, 69, 0.2)',
  },
  footerLogo: {
    color: '#A4A6AA',
  },
  resource: '#DEF2FF',
  tag: '#E6F7D5',
  shadow: 'rgba(0, 0, 0, 0.18)',
  highCharts: {
    visits: {
      baseBlue: '#3DB5C4',
      darkBlue: '#278ABC',
      notSelectedColumn: '#9DC6DB',
      students: base.green50,
      educators: base.blue50,
      all: base.orange50,
    },
    primary: {
      color: base.white,
    },
    secondary: {
      color: '#FAFAFA',
    },
  },
  lightPurple: '#6563FF',
  userProfile: {
    ncesInformation: {
      backgroundColor: base.blue5,
    },
  },
  CHANGEME: {
    0: '#00AFE8',
    1: '#4A4E5A',
    2: '#5CA8DE',
    3: '#62B796',
    4: '#77D33C',
    5: '#7C8086',
    6: '#979797',
    7: '#A4A4A4',
    8: '#BEBFC2',
    9: '#CCCCCC',
    10: '#D3D3D3',
    11: '#D68D15',
    12: '#D9534F',
    13: '#D9D9D9',
    14: '#E1C232',
    15: '#E2E1E1',
    16: '#E3F0FF',
    17: '#E7E7E7',
    18: '#EAEAEA',
    19: '#EC455A',
    20: '#ECF5FF',
    21: '#F5A623',
    22: '#F5A624',
    23: '#F6BD67',
    24: '#F8F9FA',
    25: 'rgba(0, 0, 0, 0)',
    26: 'rgba(0, 0, 0, 0.06)',
    27: 'rgba(0, 0, 0, 0.07)',
    28: 'rgba(0, 0, 0, 0.09)',
    29: 'rgba(0, 0, 0, 0.1)',
    30: 'rgba(0, 0, 0, 0.12)',
    31: 'rgba(0, 0, 0, 0.13)',
    32: 'rgba(0, 0, 0, 0.14)',
    33: 'rgba(0, 0, 0, 0.15)',
    34: 'rgba(0, 0, 0, 0.2)',
    35: 'rgba(0, 0, 0, 0.24)',
    36: 'rgba(0, 0, 0, 0.25)',
    37: 'rgba(0, 0, 0, 0.26)',
    38: 'rgba(0, 0, 0, 0.3)',
    39: 'rgba(0, 0, 0, 0.38)',
    40: 'rgba(0, 0, 0, 0.5)',
    41: 'rgba(0, 0, 0, 0.51)',
    42: 'rgba(0, 0, 0, 0.7)',
    // 43 has been replaced
    44: 'rgba(0, 119, 204, 0.2)',
    45: 'rgba(0, 119, 204, 0.3)',
    46: 'rgba(119, 151, 178, 0.16)',
    47: 'rgba(194, 194, 194, 0.5)',
    48: 'rgba(227, 242, 253, 0.51)',
    49: 'rgba(242, 242, 242, 0.95)',
    50: 'rgba(245, 245, 245, 0.51)',
    51: 'rgba(255, 255, 255, 0.15)',
    52: 'rgba(255, 255, 255, 0.5)',
    53: 'rgba(255, 255, 255, 0.8)',
    54: 'rgba(255, 255, 255, 0.85)',
    55: 'rgba(255, 255, 255, 0.9)',
    56: 'rgba(255, 255, 255, 0.95)',
    57: 'rgba(101, 99, 255, 0.1)',
    58: 'rgba(146, 64, 23, 1)',
  },
  tooltipShadow: 'rgba(56, 60, 69, 0.2)',
  uerTable: {
    orange: '#E56E00',
  },
};
