import PropTypes from 'prop-types';
import { prop, forEachObjIndexed } from 'ramda';

export default class PropTypesPresenter<PT extends PropTypes.ValidationMap<any>> {
  propTypes: PT;

  constructor(propTypes: PT, methods: any) {
    this.propTypes = propTypes;

    forEachObjIndexed((_type, name) => {
      this[name as string] = prop(name);
    }, propTypes);

    forEachObjIndexed((method, name) => {
      this[name] = method.bind(this);
    }, methods);
  }

  shape() {
    return PropTypes.shape(this.propTypes);
  }
}

// Below: Full TypeScript support + inference for presenters. Only limitation is that in the second
// parameter, methods can't reference other methods on `this`; use // @ts-ignore

/**
 * Given a `propTypes` object, return a type that is a map of each key of that object to a function
 * that takes an object of this type and accesses that key.
 */
type Presenter<PT extends object> = {
  [K in keyof PT]: (obj: PropTypes.InferProps<PT>) => PropTypes.InferType<PT[K]>;
};

/** Overwrites the `this` in each method given so that it can access all of the Presenter methods. */
type WithPresenterThis<PTThis extends Presenter<object>, Methods extends object> = {
  [K in keyof Methods]: ((this: PTThis, ...params: any) => any) & Methods[K];
};

export function makePresenter<
  PT extends PropTypes.ValidationMap<unknown>,
  Methods extends Record<string, (...params: any) => any>,
>(
  propTypes: PT,
  methods: WithPresenterThis<Presenter<PT>, Methods>,
): Presenter<PT> & Methods & { shape: () => PropTypes.Requireable<PropTypes.InferProps<PT>> } {
  const presenter: any = {}; // turn off type checking within this function

  forEachObjIndexed((_type, name) => {
    presenter[name] = prop(name);
  }, propTypes);
  forEachObjIndexed((method, name) => {
    presenter[name] = method.bind(presenter);
  }, methods);
  const shape = PropTypes.shape(propTypes);
  presenter.shape = () => shape;

  return presenter;
}
