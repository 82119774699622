import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    [theme.breakpoints.up('xs')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },

    [theme.breakpoints.up('postsm')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
  maxWidth: {
    ':where(& > *)': {
      // Apply a max-width to the _children_ of the container. This lets the children override or
      // unset it if needed, for when we need certain top-level page elements to break out of the
      // limit, for example wide tables.
      // :where() is like :is(), except that it always has zero specificity, so other classes can
      // easily override it
      maxWidth: theme.maxContentWidth,
    },
  },
  fill: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    // stretch child elements horizontally, respecting their max width. To make a specific child
    // element (ex. a button) not stretch horizontally, set `alignSelf: 'flex-start'`.
    alignItems: 'stretch',
  },
}));
