const parentScopedPath = (path, scope) => (scope === 'management' ? `/management${path}` : path);

export default {
  gradingFeedback: (id, scope) => parentScopedPath(`/organization_tools/${id}/details/feedback`, scope),

  organizationOverallGradingFeedback: (id, scope) =>
    parentScopedPath(`/organization_tools/${id}/details/feedback/overall`, scope),
  organizationProductUsageGradingFeedback: (id, scope) =>
    parentScopedPath(`/organization_tools/${id}/details/feedback/product_usage`, scope),
  organizationSchedulingGradingFeedback: (id, scope) =>
    parentScopedPath(`/organization_tools/${id}/details/feedback/scheduling`, scope),
  organizationAddedValueGradingFeedback: (id, scope) =>
    parentScopedPath(`/organization_tools/${id}/details/feedback/added_value`, scope),
};
