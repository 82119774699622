const parentScopedPath = (path, scope) => (scope === 'management' ? `/management${path}` : path);

export default {
  toolsPath: () => '/tools',
  toolPath: id => `/tools/${id}`,

  toolDetailsPath: id => `/tools/${id}/details`,
  toolOverviewPath: id => `/tools/${id}/details/overview`,

  toolFeedbackPath: id => `/tools/${id}/details/feedback`,
  overallGradingFeedback: id => `/tools/${id}/details/feedback/overall`,
  productUsageGradingFeedback: id => `/tools/${id}/details/feedback/product_usage`,
  schedulingGradingFeedback: id => `/tools/${id}/details/feedback/scheduling`,
  addedValueGradingFeedback: id => `/tools/${id}/details/feedback/added_value`,

  toolPrivacyPath: id => `/tools/${id}/details/privacy`,

  toolEvidencePath: id => `/tools/${id}/details/evidence`,
  toolEvidenceFormPath: id => `/tools/${id}/details/evidence/evidence_form`,
  toolEditEvidenceFormPath: (id, reportId) => `/tools/${id}/details/evidence/evidence_form/${reportId}`,

  toolIntegrationsPath: id => `/tools/${id}/details/integrations`,

  toolNewSurveyPath: id => `/tools/${id}/surveys/new`,

  organizationToolsPath: scope => parentScopedPath('/organization_tools', scope),
  organizationToolPath: (id, scope) => parentScopedPath(`/organization_tools/${id}`, scope),

  organizationToolDetailsPath: (organizationToolId, scope) =>
    parentScopedPath(`/organization_tools/${organizationToolId}/details`, scope),
  organizationToolOverviewPath: (organizationToolId, scope) =>
    parentScopedPath(`/organization_tools/${organizationToolId}/details/overview`, scope),
  organizationToolFeedbackPath: (organizationToolId, scope) =>
    parentScopedPath(`/organization_tools/${organizationToolId}/details/feedback`, scope),
  organizationToolPricingPath: (organizationToolId, scope) =>
    parentScopedPath(`/organization_tools/${organizationToolId}/details/pricing`, scope),
  organizationToolPrivacyPath: organizationToolId => `/organization_tools/${organizationToolId}/details/privacy`,
  organizationToolEvidencePath: id => `/organization_tools/${id}/details/evidence`,
  organizationToolIntegrationsPath: id => `/organization_tools/${id}/details/integrations`,

  organizationToolImpactAnalysesPath: (id, scope) =>
    parentScopedPath(`/organization_tools/${id}/details/impact_analyses`, scope),
  organizationToolImpactAnalysisPath: (organizationToolId, id, scope) =>
    parentScopedPath(`/organization_tools/${organizationToolId}/impact_analyses/${id}`, scope),
  organizationToolImpactAnalysisUsagePath: (organizationToolId, id, scope) =>
    parentScopedPath(`/organization_tools/${organizationToolId}/impact_analyses/${id}/usage`, scope),
  organizationToolImpactAnalysisCostPath: (organizationToolId, id, scope) =>
    parentScopedPath(`/organization_tools/${organizationToolId}/impact_analyses/${id}/cost`, scope),
  organizationToolImpactAnalysisFeedbackPath: (organizationToolId, id, scope) =>
    parentScopedPath(`/organization_tools/${organizationToolId}/impact_analyses/${id}/feedback`, scope),
  organizationToolImpactAnalysisMethodologyPath: (organizationToolId, id, scope) =>
    parentScopedPath(`/organization_tools/${organizationToolId}/impact_analyses/${id}/methodology`, scope),

  learnCommunityPath: () => `/new/tools`,

  // paths created within sysadmin to pages outside of sysadmin
  // don't have the /new base appended to them automatically
  newToolOverviewPath: id => `/new/tools/${id}/details/overview`,
  newToolPath: id => `/new/tools/${id}`,
  newToolEvidencePath: id => `/new/tools/${id}/details/evidence`,
  newToolIntegrationsPath: id => `/new/tools/${id}/details/integrations`,
  organizationPath: organizationId => `/new/organizations/${organizationId}`,
  newCompanyToolPath: (companyId, toolId) => `/new/companies/${companyId}/management/tools/${toolId}`,
  newCompanyToolEvidencePath: (companyId, toolId) =>
    `/new/companies/${companyId}/management/tools/${toolId}/details/evidence`,
  // redirecting from LearnCommunity to Organization
  newOrganizationToolPath: (organizationId, toolId) => `/new/organizations/${organizationId}/tools/${toolId}`,
};
