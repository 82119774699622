import { Theme } from '@material-ui/core/styles';

const InputLabel: Theme['overrides'] = {
  MuiInputLabel: {
    root: {
      fontWeight: 400,
      marginBottom: 0,
      color: '#646974', // gray50
    },
  },
};

export default InputLabel;
