export default theme => ({
  buttonIcon: {
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'rotate(45deg)',
    transition: 'transform 150ms ease-out',
    '& svg': {
      width: 17,
    },
    margin: '0 !important',
  },

  buttonIconTransformed: {
    transform: 'rotate(0)',
  },
});
