export default theme => ({
  root: {
    cursor: 'pointer',
    minWidth: 150,
    height: 40,
    padding: '0 10px',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    fontWeight: 700,
    lineHeight: '1.5em',
    outline: 'none',
    backgroundColor: theme.palette.buttons.default.backgroundColor,
    color: theme.palette.buttons.default.color,
    textTransform: 'none',
    letterSpacing: 'normal',
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.short,
    }),
    '&:hover, &:focus': {
      textDecoration: 'none',
      backgroundColor: theme.lighten(theme.palette.buttons.default.backgroundColor, 0.05),
    },
    '&$disabled': {
      backgroundColor: theme.palette.buttons.disabled.backgroundColor,
      color: theme.palette.buttons.disabled.color,
      cursor: 'not-allowed',
    },
  },

  xsmall: {
    padding: 4,
    minWidth: 58,
    height: 24,
    fontWeight: 'normal',

    '&:hover, &:focus': {
      fontWeight: 700,
    },
  },

  iconContainer: {
    flex: 'none',
    lineHeight: 0,
    '&:not(:only-child):first-child': {
      marginRight: 8,
    },
    '&:not(:only-child):last-child': {
      marginLeft: 8,
    },
  },

  content: {
    flex: 'none',
  },

  link: {
    padding: 2,
    minWidth: 0,
    height: 'auto',
    fontWeight: 'normal',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    color: theme.palette.text.link,
    '&:hover, &[href]:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
    '&:focus': {
      backgroundColor: theme.palette.text.light,
      borderRadius: 8,
      opacity: 0.95,
      textDecoration: 'underline',
    },
    '&[href]:focus': {
      backgroundColor: theme.palette.text.light,
      borderRadius: 8,
      textDecoration: 'underline',
    },
    '&$disabled': {
      color: theme.palette.action.disabled,
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },

  colorInherit: {
    color: 'inherit',
  },

  variableWidth: {
    minWidth: 0,
  },

  fullWidth: {
    width: '100%',
  },

  disabled: {
    color: theme.palette.action.disabled,
  },

  header: {
    color: theme.palette.common.white,
    backgroundColor: 'transparent',

    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },

    '&$disabled': {
      backgroundColor: 'transparent',
    },
  },

  filter: {
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
    minWidth: 130,

    '&$disabled': {
      color: theme.lighten(theme.palette.text.secondary, 0.05),
      opacity: '0.5',
    },
  },

  transparent: {
    color: theme.palette.buttons.default.backgroundColor,
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: 'transparent',
    },

    '&:focus': {
      backgroundColor: theme.palette.text.light,
      borderRadius: 4,
      opacity: 0.95,
    },

    '&$disabled': {
      backgroundColor: 'transparent',
    },
  },

  select: {
    fontWeight: 'normal',
    color: theme.palette.text.secondary,
    borderBottom: `1px solid ${theme.palette.border}`,
    marginBottom: 10,
    backgroundColor: 'transparent',
    width: '100%',

    '& $inner': {
      justifyContent: 'space-between',
    },
  },

  thin: {
    fontSize: theme.typography.pxToRem(14),
    height: 25,
    minWidth: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },

  social: {
    minWidth: 125,
  },

  twitter: {
    backgroundColor: theme.palette.social.twitter,
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: theme.palette.social.twitter,
      color: theme.palette.common.white,
    },
  },

  facebook: {
    backgroundColor: theme.palette.social.facebook,
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: theme.palette.social.facebook,
      color: theme.palette.common.white,
    },
  },

  square: {
    minWidth: 40,
  },

  filterActive: {
    backgroundColor: 'transparent',
    color: theme.palette.buttons.default.backgroundColor,

    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.buttons.default.backgroundColor,
    },

    '&:focus': {
      backgroundColor: theme.palette.buttons.default.backgroundColor,
      color: theme.palette.buttons.default.color,
    },
  },

  inverse: {
    backgroundColor: theme.palette.buttons.default.color,
    color: theme.palette.buttons.default.backgroundColor,

    '&:hover, &:focus, &:active': {
      backgroundColor: theme.palette.filter.iconActive,
      color: theme.palette.buttons.default.backgroundColor,
    },
  },

  selectable: {
    backgroundColor: theme.palette.buttons.selectable.backgroundColor,
    color: theme.palette.buttons.selectable.color,
    paddingLeft: 24,
    paddingRight: 24,
    fontWeight: 'normal',

    '&:hover, &:focus': {
      backgroundColor: theme.lighten(theme.palette.buttons.selectable.backgroundColor, 0.01),
      color: theme.lighten(theme.palette.buttons.selectable.color, 0.01),
    },

    '&$disabled': {
      backgroundColor: theme.lighten(theme.palette.buttons.selectable.backgroundColor, 0.01),
      color: theme.lighten(theme.palette.buttons.selectable.color, 0.01),
      opacity: '0.75',
    },
  },

  selected: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.buttons.selected.backgroundColor,
    color: theme.palette.buttons.selected.color,
    paddingLeft: 24,
    paddingRight: 24,

    '&:hover, &:focus': {
      backgroundColor: theme.lighten(theme.palette.buttons.selected.backgroundColor, 0.01),
      color: theme.lighten(theme.palette.buttons.selected.color, 0.01),
    },

    '&$disabled': {
      backgroundColor: theme.lighten(theme.palette.buttons.selected.backgroundColor, 0.01),
      color: theme.lighten(theme.palette.buttons.selected.color, 0.01),
      opacity: '0.75',
    },
  },

  left: {
    '& $inner': {
      justifyContent: 'flex-start',
    },
  },

  bordered: {
    border: `1px solid ${theme.palette.buttons.default.backgroundColor}`,
    backgroundColor: theme.palette.buttons.default.color,
    color: theme.palette.buttons.default.backgroundColor,

    '&:hover, &:focus': {
      background: theme.palette.filter.iconActive,
      color: theme.palette.text.link,
      borderRadius: 4,
    },

    '&$disabled': {
      border: `1px solid ${theme.lighten(theme.palette.border, 0.01)}`,
      backgroundColor: theme.lighten(theme.palette.buttons.selected.backgroundColor, 0.01),
      color: theme.lighten(theme.palette.buttons.selected.color, 0.01),
      opacity: '0.75',
    },
  },

  bordered_without_hover_focus: {
    border: `1px solid ${theme.palette.buttons.default.backgroundColor}`,
    backgroundColor: theme.palette.buttons.default.color,
    color: theme.palette.buttons.default.backgroundColor,

    '&:hover, &:focus': {
      // must override the default
      backgroundColor: theme.palette.buttons.default.color,
    },

    '&$disabled': {
      border: `1px solid ${theme.lighten(theme.palette.border, 0.01)}`,
      backgroundColor: theme.lighten(theme.palette.buttons.selected.backgroundColor, 0.01),
      color: theme.lighten(theme.palette.buttons.selected.color, 0.01),
      opacity: '0.75',
    },
  },

  visibilityToggle: {
    backgroundColor: theme.palette.buttons.visibilityToggle.active.backgroundColor,
    color: theme.palette.buttons.visibilityToggle.active.color,
    border: `0.5px solid ${theme.palette.buttons.visibilityToggle.active.color}`,
    '&:hover': {
      backgroundColor: theme.palette.buttons.visibilityToggle.active.backgroundColor,
    },
    '&:focus': {
      backgroundColor: theme.palette.buttons.visibilityToggle.active.backgroundColor,
      border: `2px solid ${theme.palette.buttons.visibilityToggle.active.color}`,
    },
    '& $content': {
      marginLeft: -4, // shrink the distance between icon and text from 8 to 4
    },
  },

  visibilityToggleHidden: {
    backgroundColor: theme.palette.buttons.visibilityToggle.hidden.backgroundColor,
    color: theme.palette.buttons.visibilityToggle.hidden.color,
    border: `0.5px solid ${theme.palette.buttons.visibilityToggle.hidden.color}`,
    '&:hover': {
      backgroundColor: theme.palette.buttons.visibilityToggle.hidden.backgroundColor,
    },
    '&:focus': {
      backgroundColor: theme.palette.buttons.visibilityToggle.hidden.backgroundColor,
      border: `2px solid ${theme.palette.buttons.visibilityToggle.hidden.color}`,
    },
    '& $content': {
      marginLeft: -4, // shrink the distance between icon and text from 8 to 4
    },
  },

  iconAutoLeft: {
    marginLeft: 'auto !important',
  },

  iconAutoRight: {
    marginRight: 'auto !important',
  },
});
