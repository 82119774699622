import { Theme } from '@material-ui/core/styles';
import Button from './Button';
import CardContent from './CardContent';
import FilledInput from './FilledInput';
import FormControl from './FormControl';
import FormLabel from './FormLabel';
import Input from './Input';
import InputLabel from './InputLabel';
import MenuItem from './MenuItem';
import SnackbarContent from './SnackbarContent';
import TableCell from './TableCell';
import TableRow from './TableRow';
import Typography from './Typography';
import Switch from './Switch';
import Fab from './Fab';

const overrides: Theme['overrides'] = {
  ...Button,
  ...CardContent,
  ...FilledInput,
  ...FormControl,
  ...FormLabel,
  ...Input,
  ...InputLabel,
  ...MenuItem,
  ...SnackbarContent,
  ...TableCell,
  ...TableRow,
  ...Typography,
  ...Switch,
  ...Fab,
};

export default overrides;
