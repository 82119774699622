import commonRoutes from './commonRoutes';
import productRoutes from './productRoutes';
import myLibraryRoutes from './myLibraryRoutes';
import communityRoutes from './communityRoutes';
import taskRoutes from './tasksRoutes';
import managementRoutes from './managementRoutes';
import publicProfileRoutes from './publicProfileRoutes';
import organizationPublicProfileRoutes from './organizationPublicProfileRoutes';
import analysisRoutes from './analysisRoutes';
import settingsRoutes from './settingsRoutes';
import dataServiceRoutes from './dataServiceRoutes';
import externalRoutes from './externalRoutes';
import attachmentRoutes from './attachmentRoutes';
import adminRoutes from './adminRoutes';
import memberRoutes from './memberRoutes';
import mySettingsRoutes from './mySettingsRoutes';
import gradingFeedbackRoutes from './gradingFeedbackRoutes';

export default {
  ...commonRoutes,
  ...productRoutes,
  ...myLibraryRoutes,
  ...communityRoutes,
  ...taskRoutes,
  ...managementRoutes,
  ...publicProfileRoutes,
  ...managementRoutes,
  ...analysisRoutes,
  ...settingsRoutes,
  ...dataServiceRoutes,
  ...organizationPublicProfileRoutes,
  ...externalRoutes,
  ...attachmentRoutes,
  ...adminRoutes,
  ...memberRoutes,
  ...mySettingsRoutes,
  ...gradingFeedbackRoutes,
};
