import PropTypes from 'prop-types';
import humps from 'humps';
import { isNil } from 'ramda';
import { makePresenter } from 'utils/PropTypesPresenter';
import { boolToYesNo, camelize } from 'utils/strings';

const STATUS_NAMES: Record<string, string> = {
  approved: 'Approved for Use',
  pending: 'Pending',
  denied: 'Reviewed & Denied',
  unknown: 'Unknown',
  compliant: 'Compliant',
  parent_consent_required: 'Parent Consent Required',
  noncompliant: 'Noncompliant',
  not_applicable: 'Not Applicable',
};

const shape = {
  allowToRequestProducts: PropTypes.bool,
  hideRequestButton: PropTypes.bool,
  color: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.number,
  isActive: PropTypes.bool,
  isSystem: PropTypes.bool,
  name: PropTypes.string,
  showOnProductLibrary: PropTypes.bool,
  showInPublicProfile: PropTypes.bool,
  publicProfileDefaultVisible: PropTypes.bool,
  systemStatus: PropTypes.string.isRequired,
  toolsCount: PropTypes.number,
  formTemplate: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  formStatus: PropTypes.string,
};

export type CustomStatus = PropTypes.InferProps<typeof shape>;

export default makePresenter(shape, {
  humanizedIsActive(s: CustomStatus) {
    return boolToYesNo(s.isActive);
  },

  humanizedSystemStatus(s: CustomStatus) {
    return STATUS_NAMES[s.systemStatus];
  },

  getStatusesOptions(statuses: Array<string>) {
    return statuses.map(key => ({ key, value: STATUS_NAMES[key] }));
  },

  getStatusLabel(status: string) {
    return STATUS_NAMES[status];
  },

  camelizedColor(s: CustomStatus) {
    const color = this.color(s);
    return isNil(color) ? color : camelize(color);
  },

  getDecamelizingStatusLabel(status: CustomStatus) {
    // @ts-ignore
    return this.getStatusLabel(humps.decamelize(status));
  },

  formTemplateId(status: CustomStatus) {
    return status.formTemplate?.id;
  },

  formTemplateName(status: CustomStatus) {
    return status.formTemplate?.name;
  },

  showRequestButton(status: CustomStatus) {
    return status.allowToRequestProducts || !status.hideRequestButton;
  },
});
