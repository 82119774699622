export default {
  rootPath: () => '/',
  homePath: () => '/home',
  guidelinesPath: () => '/guidelines',
  comparisonPath: () => '/comparison',
  storePath: () => '/store',
  setupPath: () => '/setup',
  contactSupport: () => '/my_settings/contact-support',
  pageNotFoundPath: () => '/public/404.html',
};
