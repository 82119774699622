import { Theme } from '@material-ui/core/styles';

const TableCell: Theme['overrides'] = {
  MuiTableCell: {
    head: {
      padding: '0 8px',

      '&:first-child': {
        paddingLeft: 26,
      },

      '&:last-child': {
        paddingRight: 26,
      },
    },

    body: {
      padding: '12px 8px 9px 8px',

      '&:first-child': {
        paddingLeft: 26,
      },

      '&:last-child': {
        paddingRight: 26,
      },
    },

    paddingCheckbox: {
      width: 48,
      paddingLeft: 12,
      paddingRight: 3,
      '& + td': {
        paddingLeft: 0,
      },

      '&:first-child': {
        paddingLeft: 12,
      },
    },
  },
};

export default TableCell;
