import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

class Rotate extends PureComponent {
  static propTypes = {
    noRotate: PropTypes.bool,
    classes: PropTypes.shape().isRequired,
    children: PropTypes.node,
  };

  static defaultProps = {
    noRotate: false,
    children: null,
  };

  render() {
    const { noRotate, classes, children } = this.props;

    return <span className={cn(classes.buttonIcon, { [classes.buttonIconTransformed]: noRotate })}>{children}</span>;
  }
}

export default withStyles(styles)(Rotate);
