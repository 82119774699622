import { Theme } from '@material-ui/core/styles';

const FilledInput: Theme['overrides'] = {
  MuiFilledInput: {
    root: {
      backgroundColor: '#f5f5f5',
    },
  },
};

export default FilledInput;
