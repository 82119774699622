import React, { useMemo } from 'react';
import { create } from 'jss';
import { StylesProvider, ThemeProvider, Theme } from '@material-ui/core/styles';
import presetDefault from 'jss-preset-default';
import GlobalStyles from 'layouts/GlobalStyles';
import theme from './theme';

const jss = create(presetDefault());

interface Props {
  children: React.ReactNode;
  /** Should be memoized with `useCallback` */
  themeOverride?: (outer: Theme) => Theme;
  // flag indicating to check for top navigation enabled, default: false
  verifyTopNavigation?: boolean;
}

/**
 * Provides a `theme` object to the app via React context.
 *
 * Nesting the `ThemeProvider` component leads to overhead and breaks `.Mui-*` global classes.
 * However, we do want to support custom colors for organizations. Until we can provide these
 * overrides via CSS variables and the CSS `color-mix()` function, this can be accomplished by
 * creating a modified copy of the `theme` object and providing that once.
 *
 * Note that all `useStyles()` calls must happen in components rendered under
 * `<CustomThemeProvider>` to work correctly, yet `<CustomThemeProvider>` usually needs to be
 * rendered in a component that has access to the current `organization`.
 */
const CustomThemeProvider = ({ children, themeOverride, verifyTopNavigation = false }: Props) => {
  const themeDef = verifyTopNavigation ? { ...theme, maxContentWidth: 'unset' } : theme;

  const resolvedTheme = useMemo(() => {
    const overriddenTheme = themeOverride ? themeOverride(themeDef) : themeDef;
    // Immer returns frozen objects, but ThemeProvider mutates objects; shallow clone
    return Object.isFrozen(overriddenTheme) ? { ...overriddenTheme } : overriddenTheme;
  }, [themeOverride]);

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={resolvedTheme}>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StylesProvider>
  );
};

export default CustomThemeProvider;
