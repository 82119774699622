import { Theme } from '@material-ui/core/styles';

const Switch: Theme['overrides'] = {
  MuiSwitch: {
    switchBase: {
      color: '#fafafa',
      '&:focus-within': {
        outline: '#0374B5 solid 1px',
        outlineOffset: '-2px',
      },
    },
    root: {
      '&$checked': {
        color: '#0077CC',
      },
    },
    colorSecondary: {
      color: '#ffffff',

      '& +$track': {
        backgroundColor: '#d9dada',
        opacity: 1,
      },

      '&$checked': {
        color: '#0077CC',

        '& +$track': {
          backgroundColor: '#79b7e2',
          opacity: 1,
        },
      },

      '&$disabled': {
        color: '#FFFFFF',

        '& +$track': {
          backgroundColor: '#BDBDBD',
        },

        '&$checked': {
          color: '#C6E0F1',

          '& +$track': {
            backgroundColor: '#DEECF6',
            opacity: 1,
          },
        },
      },
    },
  },
};

export default Switch;
