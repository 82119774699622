import { Theme } from '@material-ui/core/styles';

const Input: Theme['overrides'] = {
  MuiInput: {
    root: {
      fontSize: '1rem',
      '&$disabled': {
        '-webkit-text-fill-color': '#BDBDBD',
      },
    },
    underline: {
      '&:after': {
        borderBottomColor: '#383C45',
      },
    },
  },
};

export default Input;
