import { Theme } from '@material-ui/core/styles';

const Button: Theme['overrides'] = {
  MuiButton: {
    root: {
      boxShadow: 'none !important',
      fontSize: 'inherit',
    },

    label: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },

    outlined: {
      textTransform: 'none',
      color: '#0077CC',
      border: '1px solid #E0E0E0',
      background: '#fff',
      fontWeight: 'normal',
      '&:hover': {
        backgroundColor: '#FAFAFA',
      },
    },

    outlinedPrimary: {
      textTransform: 'none',
      color: '#0077CC',
      border: '1px solid #0077CC',
      background: '#fff',
      '&:hover': {
        color: '#0077CC',
        border: [1, 'solid', '#0077CC'],
        backgroundColor: '#fff',
      },
    },

    containedSecondary: {
      textTransform: 'none',
      backgroundColor: '#0077CC',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: '#0077CC',
      },
    },

    textSecondary: {
      textTransform: 'none',
      color: '#0077CC',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    textPrimary: {
      textTransform: 'none',
      color: '#D74747',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    text: {
      fontWeight: 'normal',
      textTransform: 'none',
      letterSpacing: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    sizeSmall: {
      padding: 0,
      fontSize: `${12 / 14}rem`,
    },

    sizeLarge: {
      padding: '0 10px',
      height: 40,
      fontSize: '1rem',
    },
  },
};
export default Button;
