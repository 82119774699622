export default theme => ({
  root: {
    color: theme.palette.text.secondary,
    marginRight: 9,
    marginBottom: 3,
    marginTop: 2,
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: theme.palette.border,
    height: 25,
    padding: '0 5px 0 10px',
  },

  label: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12),
    padding: 0,
    marginRight: 15,
  },

  icon: {
    height: 10,
    width: 10,
    color: theme.palette.text.secondary,
  },
});
