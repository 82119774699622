export default {
  dataServicePath: () => '/data_service',

  dataServiceLoadFilePath: () => '/data_service/load_file',
  dataServiceDashboardPath: () => '/data_service/dashboard',

  dataServiceLoadFileUploadPath: () => '/data_service/load_file/upload_file',
  dataServiceLoadFileMapDataPath: () => '/data_service/load_file/map_data',
  dataServiceLoadFileSummaryPath: () => '/data_service/load_file/summary',
};
