import { camelize } from 'utils/keysConverter';

export default class FailResponseError extends Error {
  constructor(response, errors, detail) {
    /* eslint-disable no-param-reassign */
    errors = camelize(errors);
    detail = camelize(detail);

    super(`${response.url} ${response.status}${errors ? ` ${JSON.stringify(errors).substring(0, 200)}` : ''}`);
    this.failResponse = response;
    this.errors = errors;
    this.detail = detail; // may be undefined
  }
}
Object.defineProperty(FailResponseError.prototype, 'name', { value: 'FailResponseError' });
