import React from 'react';
import cn from 'classnames';
import MUIPaper, { PaperProps } from '@material-ui/core/Paper';
import useStyles from './useStyles';

interface Props extends PaperProps {
  outer?: boolean;
  filterActive?: boolean;
  title?: string;
  flex?: boolean;
  setRef?: PaperProps['ref'];
  contentClass?: string;
}

const Paper = ({
  classes: classesOverrides,
  children,
  className,
  outer = false,
  filterActive = false,
  title,
  flex = false,
  setRef,
  contentClass,
  ...rest
}: Props) => {
  const classes = useStyles({ classes: classesOverrides });

  const classNames = cn(classes.root, className, {
    [classes.outer]: outer,
    [classes.flex]: flex,
    [classes.filterActive]: filterActive,
  });

  return (
    <MUIPaper elevation={0} className={classNames} ref={setRef} {...rest}>
      {title && <div className={classes.title}>{title}</div>}
      <div className={cn(classes.content, contentClass)}>{children}</div>
    </MUIPaper>
  );
};

// eslint-disable-next-line lt-custom/safe-classes-prop
export default Paper;
