import React, { createContext, useContext, useMemo } from 'react';

type FlagsMap = Map<string, boolean>; // map name of flag to whether it is active

// Don't rename this type; the lt-custom/feature-flags ESLint rule looks for it and syncs it with
// config/feature_flags.yml. Go end-to-end type safety!!
type TFlags =
  | 'ff_new_login'
  | 'ff_ccp_wizard'
  | 'ff_provider_portal'
  | 'ff_integrations_tab'
  | 'ff_maintenance_section_mapper_hub'
  | 'ff_run_summarization'
  | 'ff_run_summarization_ab_test'
  | 'ff_canvas_org_library'
  | 'ff_opportunities_table';

const flagsContext = createContext<FlagsMap | null>(null);

/**
 * A hook for determining whether one or more flags are enabled for the current session. If
 * exactFlags is true, all flags given must be enabled.
 */
const useFeatureFlags = (authorizedFlags: TFlags | Array<TFlags>, exactFlags = false): boolean => {
  const authFlagsArr = typeof authorizedFlags === 'string' ? [authorizedFlags] : authorizedFlags;

  const flagsMap = useContext(flagsContext);

  if (authFlagsArr.length === 0) return true; // no flags given; consider "matched"
  if (flagsMap == null) return false; // no provider above; consider "not matched"

  // "matches" only if the requested flag is present and set to active
  const flagMatches = (requestedFlag: TFlags) => flagsMap.get(requestedFlag) ?? false;

  return exactFlags ? authFlagsArr.every(flagMatches) : authFlagsArr.some(flagMatches);
};

export default useFeatureFlags;

interface FlagsProps {
  authorizedFlags: TFlags | Array<TFlags>;
  exactFlags?: boolean;
  renderOn?: () => React.ReactNode;
  renderOff?: () => React.ReactNode;
}

/** A component matching the API of `react-feature-flags`, using `useFeatureFlags` under the hood. */
export const Flags = (props: React.PropsWithChildren<FlagsProps>) => {
  const matches = useFeatureFlags(props.authorizedFlags, props.exactFlags);
  const renderOn = props.children ? () => props.children : props.renderOn;

  return <>{matches ? renderOn?.() : props.renderOff?.()}</>;
};

interface FlagsProviderProps {
  value: Array<{ name: string; isActive: boolean }>;
}

/** A Provider for feature flags; pass value={gon.flags} */
export const FlagsProvider = ({ value, children }: React.PropsWithChildren<FlagsProviderProps>) => {
  // Use a map instead of an array for O(1) access to individual flags. This ensures the performance
  // of checking feature flags scales only by the number of flags checked (worst case), rather than
  // the number of flags that exist, as in `react-feature-flags`.
  // Only computed once, since `props.value` comes from `gon` and is static
  const flagsMap = useMemo(() => {
    const map: FlagsMap = new Map();
    value.forEach(({ name, isActive }) => map.set(name, isActive));
    return map;
  }, [value]);

  return <flagsContext.Provider value={flagsMap}>{children}</flagsContext.Provider>;
};
