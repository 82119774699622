import React from 'react';
import MUICheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';

import useStyles from './useStyles';

interface Props extends CheckboxProps {
  dataNode?: string;
}

const Checkbox = ({ classes: classesOverrides, dataNode, className, ...rest }: Props) => {
  const classes = useStyles({ classes: classesOverrides });
  return (
    <MUICheckbox
      color="default"
      className={className}
      data-node={dataNode}
      classes={{
        root: classes.root,
        checked: classes.checked,
      }}
      {...rest}
    />
  );
};

// eslint-disable-next-line lt-custom/safe-classes-prop
export default Checkbox;
