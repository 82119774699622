import PropTypes from 'prop-types';
import { pipe, isEmpty, pathOr, isNil, find, propEq, intersection, prop } from 'ramda';

import { makePresenter } from 'utils/PropTypesPresenter';
import { ratingToGrade } from 'presenters/RatingPresenter';
import { truncate, camelize } from 'utils/strings';
import { Organization } from './OrganizationPresenter';
import { Subscription } from './SubscriptionPresenter';

const EMAIL_TRUNCATED_WIDTH = 25;

export const USER_TYPES = [
  { label: 'Educator', value: 'educator' },
  { label: 'Administrator', value: 'administrator' },
  { label: 'Education IT Professional', value: 'information_technology' },
  { label: 'Vendor', value: 'product_developer' },
  { label: 'Student', value: 'student' },
  { label: 'Other', value: 'other' },
];

const SIGN_UP_STATE = {
  profile_setup: 'Profile setup',
  password_setup: 'Password setup',
  role_setup: 'Role setup',
  renew_role: 'Renew role',
  membership_setup: 'Membership setup',
  sign_up_finished: 'Sign up finished',
};

const shape = {
  id: PropTypes.number,
  organizationsForContextSwitching: PropTypes.array,
  isSystemAdministrator: PropTypes.bool,
  isLimitedSystemAdministrator: PropTypes.bool,
  isAnonymous: PropTypes.bool,
  adminedOrganizations: PropTypes.array,
  avatarUrl: PropTypes.string,
  email: PropTypes.string,
  balance: PropTypes.number,
  earnedPoints: PropTypes.number,
  redeemedPoints: PropTypes.number,
  isVerified: PropTypes.bool,
  emailVerified: PropTypes.bool,
  sendableEmail: PropTypes.bool,
  hasNoPassword: PropTypes.bool,
  hasNoDefaultOrganization: PropTypes.bool,
  professionalHeadline: PropTypes.string,
  label: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  name: PropTypes.string,
  userType: PropTypes.string,
  descriptiveRole: PropTypes.string,
  publicId: PropTypes.string.isRequired,
  publicProfile: PropTypes.bool,
  canSwitchDesign: PropTypes.bool,
  shareDataWithProductCompany: PropTypes.bool,
  organizationsWithInventoryAccess: PropTypes.array,
  isAdministrator: PropTypes.bool,
  setupWorkflowOrganizationId: PropTypes.number,
  signUpState: PropTypes.string,
  roles: PropTypes.array,
  showLearnCommunity: PropTypes.bool,
  errors: PropTypes.object,
};

export type User = PropTypes.InferProps<typeof shape>;

export default makePresenter(shape, {
  isAdminedByUser(user, organization) {
    return this.adminedOrganizations(user)
      ?.map(org => org.id)
      .includes(organization.id);
  },

  initials(user: User) {
    const firstCharUpper = (str?: string | null) => str?.[0]?.toUpperCase() ?? '';
    return firstCharUpper(user.firstName) + firstCharUpper(user.lastName);
  },

  publicIdLength(user: User) {
    return this.publicId(user).length;
  },

  isImpersonated(user: User): boolean | null | undefined {
    // @ts-ignore
    return user.impersonates;
  },

  userTypeLabel(user: User) {
    const userType = this.userType(user);
    return pipe(find(propEq('value', userType)), prop('label'))(USER_TYPES);
  },

  formattedUsedToolGrade(user: User) {
    // @ts-ignore
    const grade = this.usedToolGrade(user);
    return grade === '?' ? '--' : grade;
  },

  usedToolGrade(user: User) {
    // @ts-ignore
    return ratingToGrade(user.usedTool.userTool.rating);
  },

  usedToolStatus(user: User) {
    // @ts-ignore
    return user.usedTool.userTool.status;
  },

  formattedUsedToolStatus(user: User) {
    // @ts-ignore
    const status = this.usedToolStatus(user);
    switch (status) {
      case 'use':
        return 'Actively in Use';
      case 'watch':
        return 'Watching';
      default:
        return '--';
    }
  },

  displayName(user: User) {
    if (isNil(user)) {
      return 'Unknown user';
    }

    const name = this.name(user);
    const email = this.email(user);

    return isEmpty(name?.trim()) ? email : name;
  },

  fullName(user: User) {
    return `${this.firstName(user)} ${this.lastName(user)}`;
  },

  isSetupMode(user: User, organization: Organization) {
    const subscription: Subscription | null = pathOr(null, ['subscription'], organization);
    // @ts-ignore
    const hasFeatures = isNil(subscription) ? false : !isEmpty(subscription.features);
    return !hasFeatures && this.setupWorkflowOrganizationId(user) === organization.id;
  },

  organizationRoleValues(user: User) {
    return user.roles?.map(role => role.name);
  },

  camelizedRoleValues(user: User) {
    // @ts-ignore
    const roles = this.organizationRoleValues(user);
    return roles.map(role => camelize(role));
  },

  hasAnyAdministratorsRoles(user, organization, roles) {
    const currentAdminedOrganization = this.adminedOrganizations(user)?.find(propEq('id', organization.id));
    if (isNil(currentAdminedOrganization)) {
      return false;
    }

    return !isEmpty(intersection(roles, currentAdminedOrganization.roles));
  },

  shortEmail(user: User) {
    return truncate(this.email(user), EMAIL_TRUNCATED_WIDTH);
  },

  hasElementForContextSwitching(user: User) {
    return (!isEmpty(this.organizationsForContextSwitching(user)) || this.isSystemAdministrator(user)) ?? false;
  },

  isUserHasAccess(user: User) {
    if (this.isSystemAdministrator(user)) {
      return true;
    }

    return !this.isLimitedSystemAdministrator(user);
  },

  hasAnySystemAdministratorsRoles(user: User) {
    return this.isSystemAdministrator(user) || this.isLimitedSystemAdministrator(user);
  },

  formattedSignUpState(user: User) {
    return SIGN_UP_STATE[this.signUpState(user)!];
  },

  blockLearnCommunity(user: User) {
    return !this.showLearnCommunity(user);
  },
});
