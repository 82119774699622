import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { pipe, find, prop, propEq, reject, isEmpty, prepend, isNil } from 'ramda';
import MUISelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import { camelize } from 'utils/strings';
import { isBlank } from 'utils/Utils';
import Chip from 'components/Chip';
import Checkbox from 'components/Checkbox';
import styles from './styles';

const getValueByKey = (key, options) => pipe(find(propEq('key', key)), prop('value'))(options);

const getColorByKey = (key, options) => pipe(find(propEq('key', key)), prop('color'))(options);

const isInclude = (option, value) => value.includes(option.key);

class Select extends Component {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      }),
    ).isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(PropTypes.number),
    ]).isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    multiple: PropTypes.bool,
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
    error: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    labelId: PropTypes.string,
    color: PropTypes.string,
    isShowRemoveIcon: PropTypes.bool,
    disabledOptions: PropTypes.bool,
    thin: PropTypes.bool,
    fullWidth: PropTypes.bool,
    showCheckbox: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    multiple: false,
    placeholder: '',
    helperText: '',
    error: false,
    name: '',
    label: null,
    labelId: null,
    color: null,
    isShowRemoveIcon: true,
    disabledOptions: false,
    thin: false,
    showCheckbox: true,
  };

  handleChange = ({ target: { value } }) => {
    const { multiple } = this.props;

    if (multiple) {
      this.handleMultiplyChange(value);
    } else {
      this.handleSingleChange(value);
    }
  };

  handleSingleChange = newValue => this.props.onChange(newValue);

  handleMultiplyChange = newValue => {
    const { value } = this.props;

    if (value.includes(newValue)) {
      this.handleDelete(newValue);
    } else {
      this.handleAdd(newValue);
    }
  };

  handleDelete = key => () => {
    const { value } = this.props;
    const newValue = reject(valueItem => valueItem === key, value);
    this.props.onChange(newValue);
  };

  handleAdd = key => {
    this.props.onChange([...key]);
  };

  renderMultiplePlaceholder = keys => {
    return isEmpty(keys) && !isEmpty(this.props.placeholder);
  };

  renderMultiplyValues = keys => {
    const { options, classes, placeholder, isShowRemoveIcon } = this.props;
    const renderPlaceholder = this.renderMultiplePlaceholder(keys);
    return (
      <div className={classes.chips}>
        {renderPlaceholder && <span className={classes.placeholder}>{placeholder}</span>}
        {keys.map(key => (
          <Chip
            key={key}
            label={getValueByKey(key, options)}
            onDelete={this.handleDelete(key)}
            isShowRemoveIcon={isShowRemoveIcon}
            className={classes.chip}
          />
        ))}
      </div>
    );
  };

  renderColors = color => {
    const { classes } = this.props;

    const camelizedColor = camelize(color);
    const paintStyles = cn(classes.paint, classes[camelizedColor]);

    return (
      <div className={classes.color} key={color}>
        <label htmlFor={color} className={classes.colorLabel}>
          <input id={color} type="radio" name="color" value={color} className={classes.colorRadio} />
          <div className={paintStyles} />
        </label>
      </div>
    );
  };

  renderSingleValue = key => {
    const { options, placeholder, classes } = this.props;
    const value = getValueByKey(key, options);
    const color = getColorByKey(key, options);

    return value ? (
      <div className={classes.productStatusItem}>
        {!isNil(color) && this.renderColors(color)} <div className={classes.textContent}>{value}</div>
      </div>
    ) : (
      <span className={cn({ [classes.placeholder]: !isEmpty(placeholder) })}>{placeholder}</span>
    );
  };

  renderValue = selected => {
    const { multiple } = this.props;
    return multiple ? this.renderMultiplyValues(selected) : this.renderSingleValue(selected);
  };

  renderOptionContent = option => {
    const { multiple, value, classes, disabledOptions, showCheckbox } = this.props;
    const { value: optionValue, description: optionDescription, color } = option;

    if (multiple) {
      return (
        <>
          {!isEmpty(option.key) && showCheckbox && (
            <Checkbox checked={isInclude(option, value)} disabled={disabledOptions} />
          )}
          <div>
            <div>{optionValue}</div>
            <div className={classes.optionsDescription}>{optionDescription}</div>
          </div>
        </>
      );
    }

    return (
      <div className={classes.statusList} data-node="option_value">
        {!isNil(color) && this.renderColors(color)} {option.value}
      </div>
    );
  };

  render() {
    const {
      classes,
      className,
      options,
      label,
      value,
      placeholder,
      helperText,
      error,
      name,
      disabledOptions,
      color,
      thin,
      fullWidth,
      ...rest
    } = this.props;
    const classNames = cn(classes.root, className, { [classes.thin]: thin });
    const selectOptions = isEmpty(placeholder) ? options : prepend({ key: '', value: placeholder }, options);

    return (
      <>
        {label && (
          <InputLabel htmlFor={name} shrink={!isBlank(value)}>
            {label}
          </InputLabel>
        )}
        <MUISelect
          className={classNames}
          {...rest}
          value={value}
          onChange={this.handleChange}
          renderValue={this.renderValue}
          id={name}
          labelId={this.labelId}
          name={name}
          fullWidth={fullWidth}
          MenuProps={{ transitionDuration: { enter: 0, exit: 0 }, PopoverClasses: { root: classes.popover } }}
        >
          {selectOptions.map(option => (
            <MenuItem
              key={`${option.key}-${option.value}`}
              value={option.key}
              disabled={disabledOptions || isEmpty(option.key)}
              className={classes.listsItem}
              data-node={`${name}_menu_item`}
            >
              {this.renderOptionContent(option)}
            </MenuItem>
          ))}
        </MUISelect>
        {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </>
    );
  }
}

export default withStyles(styles)(Select);
