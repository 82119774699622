import { take, isNil } from 'ramda';
import { camelize as humpsCamelize } from 'humps';

export function truncate(text: string | null | undefined, count: number) {
  if (isNil(text)) return '';
  return count < text.length ? `${take(count, text)}…` : text;
}

export function boolToYesNo(bool: unknown) {
  return bool ? 'Yes' : 'No';
}

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function camelize(string: string) {
  return humpsCamelize(string);
}

export function toId(string: string) {
  return string.replace(/\s/g, '_');
}

// this converts camelcase text only to title case, this will not work
// for text that are seperated by space
export const convertTextToTitleCase = (value: string) => {
  const data = value.replace(/([A-Z])/g, ' $1');
  const result = capitalize(data);
  return result;
};

export const ellipsisCutOff = (text = '', length: number) => {
  if (!text) return '';
  if (text.length > length) {
    return `${text.substring(0, length)}...`;
  }
  return text;
};
