import React from 'react';
import cn from 'classnames';
import useStyles from './useStyles';

interface Props extends React.ComponentProps<'div'> {
  rootClassName?: string;
  wide?: boolean /** if true, do not constrain the max-width of this container's children */;
  fill?: boolean /** if true, flex-grow to take available vertical space, typically screen height */;
}

const Container = ({ className = '', rootClassName, wide = false, fill = false, ...rest }: Props) => {
  const classes = useStyles();
  const style: string = cn(rootClassName || classes.root, className, !wide && classes.maxWidth, fill && classes.fill);

  return <div className={style} {...rest} />;
};

export default Container;
