import { useSelector } from 'react-redux';
import { Organization } from 'presenters/OrganizationPresenter';
import { User } from 'presenters/UserPresenter';

// micro-optimization: don't recreate this function every time `useOrganization` is called
const orgSelector = (state: any): Organization =>
  state.AppReducer?.organization ?? state.OrganizationPublicProfileAppReducer.currentOrganization;

export const useOrganization = () => useSelector(orgSelector);

// micro-optimization: don't recreate this function every time `useCurrentUser` is called
const userSelector = (state: any): User | null =>
  state.AppReducer?.user ?? state.OrganizationPublicProfileAppReducer.currentUser ?? null;

export const useCurrentUser = () => useSelector(userSelector);

// At some point, this file will also have `useSelector`, `usePPLSelector`, etc. for well-typed
// access to our Redux stores. We can't patch the types of the imports from `react-redux`; it has to
// be another file. So I'll either do another codemod or add a lint rule to move our existing usages
// over, in both JS and TS files.
