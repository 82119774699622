import PropTypes from 'prop-types';
import { makePresenter } from 'utils/PropTypesPresenter';

// @ts-ignore
const CURRENT_USER = gon.currentUser;

const shape = {
  expiredAt: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  kind: PropTypes.string,
  paymentRequired: PropTypes.bool,
  state: PropTypes.string,
};

export type Subscription = PropTypes.InferProps<typeof shape>;

export default makePresenter(shape, {
  hasFeature(subscription: Subscription | null | undefined, feature: string, alwaysOnForSysAdmin = true) {
    const { isSystemAdministrator } = CURRENT_USER;
    if (isSystemAdministrator && alwaysOnForSysAdmin) {
      return true;
    }

    if (!subscription) {
      return false;
    }

    if (subscription.state === 'disabled') {
      return false;
    }

    return !!this.features(subscription).find(f => f === feature);
  },
  isPaid(subscription: Subscription) {
    return this.paymentRequired(subscription);
  },
});
