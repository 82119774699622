import { Theme } from '@material-ui/core/styles';

const Fab: Theme['overrides'] = {
  MuiFab: {
    primary: {
      backgroundColor: '#0077CC',
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#0077CC',
      },
    },
  },
};

export default Fab;
