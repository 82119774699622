import { Theme } from '@material-ui/core/styles';

const MenuItem: Theme['overrides'] = {
  MuiMenuItem: {
    root: {
      fontSize: '1rem',
      fontWeight: 'normal',
    },
  },
};

export default MenuItem;
