export default {
  managementPath: () => '/management',

  // Organization Tools
  managementOrganizationToolsPath: () => '/management/organization_tools',
  managementOrganizationToolPath: id => `/management/organization_tools/${id}`,

  // Organization Tools > Details
  managementOrganizationToolOverviewPath: id => `/management/organization_tools/${id}/details/overview`,
  managementOrganizationToolDetailsOverviewPath: id => `/management/organization_tools/${id}/details/overview`,
  managementOrganizationToolDetailsPath: id => `/management/organization_tools/${id}/details`,
  managementOrganizationToolDetailsProductSettingsPath: id =>
    `/management/organization_tools/${id}/details/product_settings`,
  managementOrganizationToolDetailsMembersPath: id => `/management/organization_tools/${id}/details/members`,
  managementOrganizationToolDetailsPrivacyPath: id => `/management/organization_tools/${id}/details/privacy`,
  managementOrganizationToolDetailsEvidencePath: id => `/management/organization_tools/${id}/details/evidence`,
  managementOrganizationToolDetailsIntegrationsPath: id => `/management/organization_tools/${id}/details/integrations`,
  managementOrganizationToolImpactAnalysesPath: id => `/management/organization_tools/${id}/details/impact_analyses`,
  managementOrganizationToolDetailsUsagePath: id => `/management/organization_tools/${id}/details/usage`,
  managementOrganizationToolDetailsImpactPath: id => `/management/organization_tools/${id}/details/impact`,
  managementOrganizationToolPricingPath: id => `/management/organization_tools/${id}/details/pricing`,

  // Members
  managementOrganizationToolDetailsMemberOrganizations: id =>
    `/management/organization_tools/${id}/details/members/organizations`,
  managementOrganizationToolDetailsMemberEducators: id =>
    `/management/organization_tools/${id}/details/members/educators`,

  // Details > Feedback
  managementOrganizationToolFeedbackPath: id => `/management/organization_tools/${id}/details/feedback`,
  managementOrganizationToolDetailsFeedbackPath: id => `/management/organization_tools/${id}/details/feedback`,
  managementOrganizationOverallGradingFeedback: id => `/management/organization_tools/${id}/details/feedback/overall`,
  managementOrganizationProductUsageGradingFeedback: id =>
    `/management/organization_tools/${id}/details/feedback/product_usage`,
  managementOrganizationSchedulingGradingFeedback: id =>
    `/management/organization_tools/${id}/details/feedback/scheduling`,
  managementOrganizationAddedValueGradingFeedback: id =>
    `/management/organization_tools/${id}/details/feedback/added_value`,

  // Impact Analyses
  managementOrganizationToolImpactAnalysisPath: (organizationToolId, id) =>
    `/management/organization_tools/${organizationToolId}/impact_analyses/${id}`,
  managementOrganizationToolImpactAnalysisUsagePath: (organizationToolId, id) =>
    `/management/organization_tools/${organizationToolId}/impact_analyses/${id}/usage`,
  managementOrganizationToolImpactAnalysisCostPath: (organizationToolId, id) =>
    `/management/organization_tools/${organizationToolId}/impact_analyses/${id}/cost`,
  managementOrganizationToolImpactAnalysisFeedbackPath: (organizationToolId, id) =>
    `/management/organization_tools/${organizationToolId}/impact_analyses/${id}/feedback`,
  managementOrganizationToolImpactAnalysisMethodologyPath: (organizationToolId, id) =>
    `/management/organization_tools/${organizationToolId}/impact_analyses/${id}/methodology`,

  // Custom Products
  managementOrganizationToolsNewCustomProductPath: () => '/management/organization_tools/new_custom_product',
  managementOrganizationToolsNewCustomProductDetails: () => '/management/organization_tools/new_custom_product_details',
  managementOrganizationToolsEditCustomProduct: id => `/management/organization_tools/edit_custom_product/${id}`,
  managementOrganizationToolsFindMatchingProduct: () => '/management/organization_tools/find_matching_product/',
  managementOrganizationToolsFindMatchingProductById: id =>
    `/management/organization_tools/${id}/find_matching_product/`,
  managementOrganizationToolsMatchProduct: (id, ccpMappingId) =>
    `/management/organization_tools/${id}/find_matching_product/${ccpMappingId}`,

  // Tool Inquiries
  managementToolInquiriesPath: () => '/management/tool_inquiries',
  managementToolInquiryPath: id => `/management/tool_inquiries/${id}`,
  managementToolInquiryRequestsPath: id => `/management/tool_inquiries/${id}/requests`,
  managementToolInquiryReviewsPath: id => `/management/tool_inquiries/${id}/reviews`,
  managementToolInquiryPendingReviewsPath: id => `/management/tool_inquiries/${id}/pending_reviews`,
  managementToolInquiryPendingReviewsNewPath: id => `/management/tool_inquiries/${id}/pending_reviews/new`,

  // Purchase Orders
  managementPurchaseOrdersPath: () => '/management/purchase_orders',

  // Application Templates
  managementApplicationTemplatesPath: () => '/management/application_templates',
  managementNewApplicationTemplatePath: () => '/management/application_templates/new',
  managementEditApplicationTemplatePath: id => `/management/application_templates/${id}/edit`,
  managementApplicationTemplateApplicantPath: (id, applicationId, tab) =>
    `/management/application_templates/${id}/applicants/${applicationId}/application/${tab}`,
  managementApplicationTemplateApplicantsPath: id => `/management/application_templates/${id}/applicants`,

  // Feedback Report
  managementFeedbackReportPath: () => '/management/feedback_report',

  // Applications
  managementApplicationsPath: () => '/management/applications',
  managementShowApplicationPath: id => `/management/applications/${id}`,
  managementCreateApplicationPath: id => `/management/applications/${id}/new`,
  managementEditApplicationPath: id => `/management/applications/${id}/edit`,

  // Insights
  managementInsightsPath: () => '/management/insights',

  // Workflows
  managementWorkflowRunsPath: () => '/management/workflows',
  managementWorkflowRunsOrganizationToolPath: id => `/management/workflows/organization_tools/${id}`,

  // Tools
  managementToolsPath: () => '/management/tools',
  managementToolPath: id => `/management/tools/${id}`,

  // Tools > Details
  managementToolDetailsPath: id => `/management/tools/${id}/details`,
  managementToolDetailsOverviewPath: id => `/management/tools/${id}/details/overview`,
  managementToolDetailsFeedbackPath: id => `/management/tools/${id}/details/feedback`,
  managementToolDetailsUsagePath: id => `/management/tools/${id}/details/usage`,
  managementToolDetailsImpactPath: id => `/management/tools/${id}/details/impact`,
  managementToolDetailsSSOPath: id => `/management/tools/${id}/details/sso`,
  managementToolDetailsEvidencePath: id => `/management/tools/${id}/details/evidence`,
  managementToolDetailsIntegrationsPath: id => `/management/tools/${id}/details/integrations`,
  managementToolDetailsEditEvidenceFormPath: (id, reportId) =>
    `/management/tools/${id}/details/evidence/evidence_form/${reportId}`,
  managementToolDetailsEvidenceFormPath: id => `/management/tools/${id}/details/evidence/evidence_form`,
};
