import Color from 'color';
import { createMuiTheme } from '@material-ui/core/styles';

import palette from './palette';
import overrides from './overrides';
import './theme.types'; // tweak MUI types to match our customizations

// Instructure's Mini Style Guide
// https://www.figma.com/file/QuIGgv2CneoY2YByxmYzoO/%F0%9F%93%84-[DOC]-InstUI-Mini-Style-Guide-(IMSG)?type=design&node-id=0-1&mode=design&t=QU3PQyrolQbpRy5i-0
//

// treat 1rem as normally equivalent to 14px. If the user scales the browser's default font size,
// 1rem will grow and shrink accordingly.
export const htmlFontSize = 14;

export const fade = (color: string, alpha: number) => Color(color).alpha(alpha).string();

export const lighten = (color: string, amount: number) => Color(color).lighten(amount).string();

export const darken = (color: string, amount: number) => Color(color).darken(amount).string();

const theme = createMuiTheme({
  props: {
    MuiDivider: {
      'aria-hidden': true,
    },
  },
  maxContentWidth: 1176,
  breakpoints: {
    // keys: ['empty', 'xs', 'postxs', 'sm', 'postsm', 'md', 'postmd', 'lg', 'postlg', 'xl'],
    values: {
      empty: 0,
      xs: 505,
      postxs: 570,
      sm: 600,
      postsm: 840,
      md: 960,
      postmd: 1024,
      lg: 1280,
      postlg: 1440,
      xl: 1920,
    },
  },
  typography: {
    htmlFontSize,
    fontSize: 14,
    headline: {
      fontSize: `${38 / htmlFontSize}rem`,
      fontWeight: 700,
      color: palette.text.primary,
    },
    title: {
      fontSize: `${28 / htmlFontSize}rem`,
      fontWeight: 400,
    },
    subheading: {
      fontSize: `${16 / htmlFontSize}rem`,
      fontWeight: 700,
    },
    fontFamily: ['Lato', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
  palette,
  mixins: {
    doubleGutters: () => ({
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
      },
    }),
  },
  // App specific variables
  headerHeight: 35,
  headerHeightTop: 64,
  navbarWidth: 256,
  navbarCollapsedWidth: 90,
  drawerWidth: 320,
  overrides,
  lighten,
  darken,
  fade,
});

export const { pxToRem } = theme.typography;

export default theme;
