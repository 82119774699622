import { Theme } from '@material-ui/core/styles';

const SnackbarContent: Theme['overrides'] = {
  MuiSnackbarContent: {
    root: {
      flexWrap: 'nowrap',
      border: 'none',
    },
  },
};

export default SnackbarContent;
