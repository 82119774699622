import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import MUIChip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import Icon from 'components/Icon';
import styles from './styles';

const Chip = ({ classes, className, onDelete, isShowRemoveIcon, ...rest }) => {
  const classNames = cn(classes.root, className);
  const deleteHandler = isShowRemoveIcon ? onDelete : null;

  return (
    <MUIChip
      className={classNames}
      classes={{ root: classes.root, label: classes.label }}
      onDelete={deleteHandler}
      deleteIcon={<Icon icon="close" className={classes.icon} />}
      {...rest}
    />
  );
};

Chip.propTypes = {
  classes: PropTypes.shape().isRequired,
  className: PropTypes.string,
  onDelete: PropTypes.func,
  isShowRemoveIcon: PropTypes.bool,
};

Chip.defaultProps = {
  className: '',
  isShowRemoveIcon: true,
};

export default withStyles(styles)(Chip);
