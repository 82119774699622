import { Theme } from '@material-ui/core/styles';

const CardContent: Theme['overrides'] = {
  MuiCardContent: {
    root: {
      padding: 30,
      '&:last-child': {
        paddingBottom: 36,
      },
    },
  },
};

export default CardContent;
