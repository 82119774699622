import React from 'react';
import cn from 'classnames';
import MUIIconButton, { IconButtonProps as MUIIconButtonProps } from '@material-ui/core/IconButton';
import ButtonBase from 'components/ButtonBase';
import Icon from 'components/Icon';
import useStyles from './useStyles';

export interface IconButtonProps extends MUIIconButtonProps {
  icon: Parameters<typeof Icon>[0]['icon']; // we'll improve this when we address the comment in components/Icon/index.tsx
  iconWidth?: number;
  iconHeight?: number;
  href?: string;
}

const IconButton = ({
  classes: classesOverrides,
  className,
  icon,
  children,
  disabled,
  iconWidth,
  iconHeight,
  ...other
}: IconButtonProps) => {
  const classes = useStyles({ classes: classesOverrides });
  const buttonProps = {
    component: ButtonBase,
    disabled,
    className: cn(classes.root, className),
    ...other,
  };
  return (
    <MUIIconButton {...buttonProps}>
      <span className={classes.inner}>
        {icon && <Icon icon={icon} className={classes.icon} width={iconWidth} height={iconHeight} />}
        {children}
      </span>
    </MUIIconButton>
  );
};

// Can disable the rule because we're passing { classes } to useStyles()
// eslint-disable-next-line lt-custom/safe-classes-prop
export default IconButton;
