import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.border}`,
    display: 'block',
  },

  content: {
    padding: 0,
    height: 'inherit',
  },

  flex: {
    '& $content': {
      display: 'flex',
      width: '100%',
    },
  },

  outer: {
    '& $content': {
      padding: theme.spacing(3),
    },
  },

  filterActive: {
    backgroundColor: theme.palette.CHANGEME[48],
  },

  title: {
    padding: '10px 16px',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(16),
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.gray0,
  },
}));
