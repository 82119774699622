import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  active: {
    fontWeight: 'bold',
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
}));
