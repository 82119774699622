import { Theme } from '@material-ui/core/styles';

const FormControl: Theme['overrides'] = {
  MuiFormControl: {
    root: {
      marginBottom: 15,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
};

export default FormControl;
